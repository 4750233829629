import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../../components/Core";

import imgHalf from "../../../assets/image/flop/products/flop-gto/flop-gto-michael-acevedo-flop.png";
import FlopFormattedMessageWrapper from "../../../components/Flop/FlopFormattedMessageWrapper";

const GtoMichaelFlop = () => (
  <Section bg="dark" className="pt-0 mt-0 mb-0 pb-0">
    <div className="overflow-hidden">
      <Container>
        <Row className="justify-content-around align-items-center">
          <Col
            md={8}
            lg={6}
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
            style={{paddingLeft: 20, paddingRight: 20}}
          >
            <Section py={[0, 0, "30px", "30px"]}>
              <div className="omga-02__content-text section-title order-lg-1">
                <Title color="light">
                  <FlopFormattedMessageWrapper id="products.flopgto.michael.title" />
                </Title>
                <Text color="light">
                  <FlopFormattedMessageWrapper id="products.flopgto.michael.text" />
                </Text>
              </div>
            </Section>
          </Col>
          <Col
            md={8}
            lg={6}
            className="position-relative mb-5 mb-lg-0 d-flex justify-content-center"
          >
            <div className="h-100">
              <img
                src={imgHalf}
                alt="Michael Acevedo is providing you the best Game Theroy Optimal Tool"
                className="img-half img-fluid"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </Section>
);

export default GtoMichaelFlop;
