import React, { useState } from "react";
import { Row, Col, Modal, Spinner } from "react-bootstrap";

import { Title, Button, Box, Text, Input } from "../../../components/Core";
import FlopFormattedMessageWrapper from "../../../components/Flop/FlopFormattedMessageWrapper";

const GtoAffialiate = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const isFormValid = name && email;

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { ok } = await fetch(`${process.env.GATSBY_CONTACT_US_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({
          contactName: name,
          contactEmail: email,
          subject: "FLOP Gto Affiliate",
          // We manually setup the origin
          contactOrigin: "FLOP_APP_WEBSITE",
          content: "Affiliation program",
        }),
      });
      if (ok) {
        setIsSuccessModalVisible(true);
        setName("");
        setEmail("");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Row className="justify-content-center align-items-center pt-5">
        <Col lg="6" className="text-center">
          <div>
            <Title>
              <FlopFormattedMessageWrapper id="products.flopgto.affiliate.title" />
            </Title>
            <Text>
              <FlopFormattedMessageWrapper id="products.flopgto.affiliate.text" />
            </Text>
          </div>
        </Col>
      </Row>
      <Box id="form" px={[2, null]} py={[4, null, null, 5, 5]}>
        <Row className="align-items-center justify-content-center">
          <Col md="10" lg="8" xl="4">
            <form onSubmit={(e) => e.preventDefault()}>
              <Box mb={3}>
                <Input
                  type="text"
                  placeholder="Full name"
                  required
                  onChange={({ target: { value } }) => setName(value)}
                  value={name}
                />
              </Box>
              <Box mb={3}>
                <Input
                  type="email"
                  placeholder="Email address"
                  required
                  onChange={({ target: { value } }) => setEmail(value)}
                  value={email}
                />
              </Box>
              <Box mb={1}>
                <Text
                  fontWeight={400}
                  fontSize={"12px"}
                  color="grey"
                  className="text-center"
                >
                  <FlopFormattedMessageWrapper id="products.flopgto.affiliate.text-cta" />
                </Text>
              </Box>
              <Box mt={0}>
                <Button
                  bg={isFormValid ? "#4D8DCC" : "#D2D9DF"}
                  style={{
                    ...((!isFormValid || isSubmitting) && {
                      pointerEvents: "none",
                    }),
                  }}
                  onClick={onSubmit}
                  type="submit"
                  width="100%"
                >
                  {isSubmitting ? (
                    <Spinner animation="border" />
                  ) : (
                    <FlopFormattedMessageWrapper id="products.flopgto.affiliate.cta" />
                  )}
                </Button>
              </Box>
            </form>
          </Col>
        </Row>
      </Box>
      <Modal
        show={isSuccessModalVisible}
        onHide={() => setIsSuccessModalVisible(false)}
        centered
      >
        <Box display="flex" flexDirection="column" alignItems="center" p={4}>
          <Title variant="small-section">FLOP GTO Affiliation</Title>
          <Text>
            Thank you for reaching out to First Land of Poker! Your message has been successfully received. Our Business Development team is on the case and will get back to you as soon as possible. We appreciate your interest and look forward to discussing how our innovative solutions can elevate your poker experience.
          </Text>
        </Box>
        <Box display="flex" justifyContent="flex-end" px={16} py={2}>
          <Text
            color="#4D8DCC"
            onClick={() => setIsSuccessModalVisible(false)}
            style={{ cursor: "pointer" }}
          >
            Got it !
          </Text>
        </Box>
      </Modal>
    </>
  );
};

export default GtoAffialiate;
