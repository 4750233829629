import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

import { Section, Title, Button } from "../../../components/Core";

import FlopFormattedMessageWrapper from "../../../components/Flop/FlopFormattedMessageWrapper";

const Table = styled.table`
  thead,
  tr,
  tbody {
    display: block;
  }
  thead tr .sin-title-box {
    padding: 0 20px;
    height: 60px;
    color: #19191b;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.56px;
  }
  .title-col {
    min-width: 250px;
  }
  .basic-col,
  .standard-col,
  .premium-col,
  .ent-col {
    min-width: 300px;
  }

  .colored-row {
    background: #f7f7fb;
    border-top: 2px solid #fff;
    &:first-child {
      border-top: 0;
    }
    .sin-box.title-col {
      color: #1a2025;
      font-weight: 700;
    }
    .sin-box {
      position: relative;
      padding: 0 20px;
      height: 65px;
      color: #1a2025;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      .table-icon {
        font-size: 20px;
        &.neg {
          color: #f04037;
        }
        &.pos {
          color: #67d216;
        }
      }
      &:before {
        content: "";
        background: #fff;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        width: 1px;
      }
    }
  }
`;

const ButtonSm = styled(Button)`
  width: 167px;
  min-width: auto;
  height: 50px;
  border-radius: 28px;
  border: 1px solid #eae9f2;
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  &:hover,
  &:focus {
    border: ${({ theme }) => `1px solid ${theme.colors.red}`} !important;
    background-color: ${({ theme }) => `${theme.colors.red}`} !important;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const GtoPricing = () => (
  <>
    <Section className="pt-4 pb-0">
      <div className="pt-3" id="pricing"></div>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="6">
            <Title variant="hero">
              <FlopFormattedMessageWrapper id="products.flopgto.pricing.title" />
            </Title>
          </Col>
        </Row>
      </Container>
    </Section>
    <Section className="pt-4">
      <Container>
        <div
          css={`
            overflow: auto;
            padding-bottom: 30px;
          `}
        >
          <Table>
            <thead>
              <tr>
                <th scope="col" className="title-col sin-title-box" />
                <th scope="col" className="basic-col sin-title-box text-center">
                  <FlopFormattedMessageWrapper id="products.flopgto.pricing.free.title" />
                </th>
                <th
                  scope="col"
                  className="standard-col sin-title-box text-center"
                >
                  <FlopFormattedMessageWrapper id="products.flopgto.pricing.premium.title" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="title-col sin-box" />
                <td className="basic-col sin-box text-center">
                  <FlopFormattedMessageWrapper id="products.flopgto.pricing.free.text" />
                </td>
                <td className="basic-col sin-box text-center">
                  <FlopFormattedMessageWrapper id="products.flopgto.pricing.premium.text" />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr className="colored-row">
                <th scope="row" className="title-col sin-box">
                  MTT 9 Max
                </th>
                <td className="basic-col sin-box">10, 12, 15 bbs</td>
                <td className="standard-col sin-box">
                  10, 12, 15,{" "}
                  <strong>
                    17, 20, 25, 30, 40, 50, 60, 80 bbs (& 100 bbs coming soon)
                  </strong>
                </td>
              </tr>
              <tr className="colored-row">
                <th scope="row" className="title-col sin-box">
                  MTT 6 Max
                </th>
                <td className="basic-col sin-box">10, 12, 15 bbs</td>
                <td className="standard-col sin-box">
                  10, 12, 15, <strong>17, 20, 25, 40, 50, 60, 80 bbs</strong>
                </td>
              </tr>
              <tr className="colored-row">
                <th scope="row" className="title-col sin-box">
                  MTT HU
                </th>
                <td className="basic-col sin-box">
                  <span className="table-icon neg">
                    <i className="fas fa-times" />
                  </span>
                </td>
                <td className="standard-col sin-box">
                  <strong>10, 12, 15, 17, 20, 27, 35, 50, 75, 100 bbs</strong>
                </td>
              </tr>
              <tr className="colored-row">
                <th scope="row" className="title-col sin-box">
                  MTT BvB limps (9-max)
                </th>
                <td className="basic-col sin-box">
                  <span className="table-icon neg">
                    <i className="fas fa-times" />
                  </span>
                </td>
                <td className="standard-col sin-box">
                  <strong>
                    10, 12, 15, 17, 20, 25, 30, 40, 50, 60, 80, 100 bb
                  </strong>
                </td>
              </tr>
              <tr className="colored-row">
                <th scope="row" className="title-col sin-box">
                  MTT BvB limps (6-max)
                </th>
                <td className="basic-col sin-box">
                  <span className="table-icon neg">
                    <i className="fas fa-times" />
                  </span>
                </td>
                <td className="standard-col sin-box">
                  <strong>10, 12, 15 bbs</strong>
                </td>
              </tr>
              <tr className="colored-row">
                <th scope="row" className="title-col sin-box">
                  Spin simple
                </th>
                <td className="basic-col sin-box">8, 10 bbs </td>
                <td className="standard-col sin-box">
                  8, 10,<strong> 12, 15, 17, 20, 25 bbs</strong>
                </td>
              </tr>
              <tr className="colored-row">
                <th scope="row" className="title-col sin-box">
                  Spin Full
                </th>
                <td className="basic-col sin-box">8, 10 bbs </td>
                <td className="standard-col sin-box">
                  8, 10,<strong> 12, 15, 17, 20, 25 bbs</strong>
                </td>
              </tr>
              <tr className="colored-row">
                <th scope="row" className="title-col sin-box">
                  Cash Game NL100
                </th>
                <td className="basic-col sin-box">
                  <span className="table-icon neg">
                    <i className="fas fa-times" />
                  </span>
                </td>
                <td className="standard-col sin-box">
                  <strong>100 bb</strong>
                </td>
              </tr>
              <tr className="colored-row">
                <th scope="row" className="title-col sin-box">
                  Cash Game NL500
                </th>
                <td className="basic-col sin-box">
                  <span className="table-icon neg">
                    <i className="fas fa-times" />
                  </span>
                </td>
                <td className="standard-col sin-box">
                  <strong>100 bb</strong>
                </td>
              </tr>
              <tr className="price-block-row">
                <th scope="row" className="title-col sin-box" />
                <td className="basic-col sin-box">
                  <div className="mt-5 ml-3 pr-3">
                    <Title variant="card">
                      <FlopFormattedMessageWrapper id="products.flopgto.pricing.free.title" />
                    </Title>
                    <small className="d-block mb-4">
                      <FlopFormattedMessageWrapper id="products.flopgto.pricing.no-credit-card-required" />
                    </small>
                    <a
                      href="https://flopgto.com/signup?type=free"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ButtonSm size="sm">
                        <FlopFormattedMessageWrapper id="products.flopgto.pricing.get-started" />
                      </ButtonSm>
                    </a>
                  </div>
                </td>
                <td className="basic-col sin-box">
                  <div className="mt-5 ml-3 pr-3">
                    <Title variant="card">30€/mo</Title>
                    <small className="d-block mb-4">
                      <FlopFormattedMessageWrapper id="products.flopgto.pricing.no-commitment" />
                    </small>
                    <a
                      href="https://flopgto.com/signup?type=premium"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ButtonSm size="sm">
                        <FlopFormattedMessageWrapper id="products.flopgto.pricing.get-started" />
                      </ButtonSm>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>
    </Section>
  </>
);

export default GtoPricing;
