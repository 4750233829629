import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/flop-products/flop-gto/Hero";
import GtoAccess from "../sections/flop-products/flop-gto/GtoAccess";
import GtoMichaelFlop from "../sections/flop-products/flop-gto/GtoMichaelFlop";
import GtoUpcomingDetail from "../sections/flop-products/flop-gto/GtoUpcomingDetail";
import GtoPricing from "../sections/flop-products/flop-gto/GtoPricing";
import GtoPatrikWins from "../sections/flop-products/flop-gto/GtoPatrikWins";
import GtoAffialiate from "../sections/flop-products/flop-gto/GtoAffialiate";
import Seo from "../components/Seo/seo";

const FlopGtoPage = () => {
  return (
    <PageWrapper headerDark bg="black">
      <Seo
        title="Flop Gto"
        desc="Learn Game Theory Optimal with the best tool."
      />
      <Hero />
      <GtoAccess />
      {/* <GtoGift /> */}
      <GtoPricing />
      {/* <GtoContentDetail /> */}
      <GtoPatrikWins />
      <GtoUpcomingDetail />
      <GtoMichaelFlop />
      {/* <GtoModernPokerTheory /> */}
      {/* <GtoPokerBook /> */}
      <GtoAffialiate />
    </PageWrapper>
  );
};
export default FlopGtoPage;
