import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text, Box } from "../../../components/Core";
import FlopFormattedMessageWrapper from "../../../components/Flop/FlopFormattedMessageWrapper";

import FlopGtoTutorialVid from "../../../assets/videos/flop-gto-tutorial-michael-acevedo.mp4";
import styled from "styled-components";

const VidFull = styled.video`
  width: 100%;
  height: 100%;
`;

const GtoAccess = () => (
  <>
    <Section bg="#f7f7fb">
      <Container>
        <Row className="justify-content-center">
          <Col lg="10" className="text-center">
            <div>
              <Title>
                <FlopFormattedMessageWrapper id="products.flopgto.access.title" />
              </Title>
              <Text>
                <FlopFormattedMessageWrapper id="products.flopgto.access.text1" />
              </Text>
              <Text>
                <FlopFormattedMessageWrapper id="products.flopgto.access.text2" />
              </Text>
            </div>
          </Col>
        </Row>
        <Box
          borderRadius={10}
          className="position-relative overflow-hidden mb-0 pb-0"
        >
          <VidFull controls className="mb-0 pb-0">
            <source src={FlopGtoTutorialVid} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </VidFull>
        </Box>
      </Container>
    </Section>
  </>
);

export default GtoAccess;
