import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../../components/Core";
import FlopFormattedMessageWrapper from "../../../components/Flop/FlopFormattedMessageWrapper";
import { device } from "../../../utils";

import flopGtoPresentation from "../../../assets/image/flop/products/flop-gto/flop-gto-presentation.png";
import flopGtoLogo from "../../../assets/image/flop/products/flop-gto/flop-gto-logo-dark.png";

const ImgRight = styled.img`
  max-width: 80%;
  @media ${device.sm} {
    max-width: 80%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="black" className="position-relative">
        <Box px={[1, 1, 1, 5, 6]} pt={[5, 3, 5, 3, 0]}>
          <Col>
            <Row className="justify-content-center align-items-center">
              <Col lg="6" md="8" sm="9" className="order-lg-2">
                <div className="text-center text-lg-right position-relative">
                  <div
                    className="img-main"
                    data-aos="fade-down"
                    data-aos-duration="750"
                    data-aos-once="true"
                    data-aos-delay="500"
                  >
                    <ImgRight
                      src={flopGtoPresentation}
                      alt="Flop Gto Presentation"
                    />
                  </div>
                </div>
              </Col>
              <Col lg="6" className="order-lg-1" style={{paddingLeft: 20, paddingRight: 20}}>
                <div
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <Box pr={5} pt={[null]}>
                    <img
                      className="mb-3"
                      src={flopGtoLogo}
                      alt="Flop Gto Logo"
                    />
                    <Title color="light" variant="hero">
                      <FlopFormattedMessageWrapper id="products.flopgto.hero.title" />
                    </Title>
                    <Text color="light" mb={4}>
                      <FlopFormattedMessageWrapper id="products.flopgto.hero.subtitle" />
                    </Text>
                  </Box>
                </div>
                <div className="d-flex flex-column align-items-start mb-3">
                  <a href="#pricing">
                    <Button mb={0} bg="red">
                      <FlopFormattedMessageWrapper id="products.flopgto.hero.start" />
                    </Button>
                  </a>
                  <Box>
                    <Text
                      fontWeight={200}
                      fontSize={"14px"}
                      color="grey"
                      className="pl-4"
                    >
                      <FlopFormattedMessageWrapper id="products.flopgto.hero.disclaimer" />
                    </Text>
                  </Box>
                </div>
              </Col>
            </Row>
          </Col>
        </Box>
      </Section>
    </>
  );
};

export default Hero;
