import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Box, Text, Title } from "../../../components/Core";
import styled, { keyframes } from "styled-components";
import FlopFormattedMessageWrapper from "../../../components/Flop/FlopFormattedMessageWrapper";
import { rgba } from "polished";

const animRippleOut = keyframes`
    100% {
      top: -12px;
      right: -12px;
      bottom: -12px;
      left: -12px;
      opacity: 0;
    }
`;

const InnerCircle = styled(Box)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
`;

const OuterCircle = styled(Box)`
  width: 62px;
  height: 62px;
  border-radius: 50%;
  position: absolute;
  transform: perspective(1px) translateZ(0);
  background-color: ${({ theme, bg }) => rgba(theme.colors[bg], 0.06)};
  &:before {
    content: "";
    position: absolute;
    border: ${({ theme, bg }) => `6px solid ${rgba(theme.colors[bg], 0.08)}`};
    border-radius: 500px;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    animation: ${animRippleOut} 1.2s linear 1s infinite;
  }
`;

const FeatureCard = ({ color = "primary", title, children, ...rest }) => (
  <Box width={"100%"} bg="greyBg" {...rest}>
    <div className="d-flex justify-content-start justify-content-lg-center align-items-start">
      <Box
        size={69}
        minWidth={69}
        minHeight={69}
        borderRadius="50%"
        color={color}
        fontSize="28px"
        className="d-flex justify-content-center align-items-center"
        mr="20px"
      >
        <InnerCircle bg={color} />
        <OuterCircle bg={color} />
      </Box>
      <div>
        <Text
          color="heading"
          as="h3"
          fontSize={4}
          fontWeight={500}
          letterSpacing={-0.75}
          mb={0}
          ml={0}
        >
          <FlopFormattedMessageWrapper id={title} />
        </Text>
        <Text fontSize={2} lineHeight={1.75}>
          {children}
        </Text>
      </div>
    </div>
  </Box>
);

const GtoUpcomingDetail = () => (
  <>
    <Section bg="light">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col lg="6" className="text-center">
            <div>
              <Title>
                <FlopFormattedMessageWrapper id="products.flopgto.upcomingDetail.title" />
              </Title>
              <Text>
                <FlopFormattedMessageWrapper id="products.flopgto.upcomingDetail.subtitle" />
              </Text>
            </div>
          </Col>
        </Row>
        <Row className="mt-5 align-items-center justify-content-center">
          <Col md="6" xl="4" className="mb-5">
            <FeatureCard
              color="blue"
              title="products.flopgto.upcomingDetail.content1"
            />
          </Col>
          <Col md="6" xl="4" className="mb-5">
            <FeatureCard
              color="red"
              title="products.flopgto.upcomingDetail.content2"
            />
          </Col>
          <Col md="6" xl="4" className="mb-5">
            <FeatureCard
              color="lightGreen"
              title="products.flopgto.upcomingDetail.content3"
            />
          </Col>
          <Col md="6" xl="4" className="mb-5">
            <FeatureCard
              color="orangeSecond"
              title="products.flopgto.upcomingDetail.content4"
            />
          </Col>
          <Col md="6" xl="4" className="mb-5">
            <FeatureCard
              color="purpleSecond"
              title="products.flopgto.upcomingDetail.content5"
            />
          </Col>
          {/* <Col md="6" xl="4" className="mb-5">
            <FeatureCard
              color="dark"
              title="products.flopgto.upcomingDetail.content6"
            />
          </Col> */}
        </Row>
      </Container>
    </Section>
  </>
);

export default GtoUpcomingDetail;
