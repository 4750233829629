import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Text } from "../../../components/Core";

import imgHalf from "../../../assets/image/flop/products/flop-gto/flop-gto-patrik-antonius.png";
import FlopFormattedMessageWrapper from "../../../components/Flop/FlopFormattedMessageWrapper";

const GtoPatrikWins = () => (
  <Section bg="dark" className="pt-0 mt-0 mb-0 pb-0">
    <div className="overflow-hidden">
      <Container className="mt-0 pt-0 mb-0 pb-0">
        <Row className="justify-content-around align-items-center  mb-0 pb-0 mt-O pt-0">
          <Col
            md={8}
            lg={6}
            className="position-relative mt-0 mb-5 mb-lg-0 d-flex justify-content-center"
          >
            <div className="h-100">
              <img
                src={imgHalf}
                alt="Patrik uses Flop Gto to improve his poker skills"
                className="img-half img-fluid"
              />
            </div>
          </Col>
          <Col
            md={8}
            lg={6}
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <Section py={[5, null, null, "190px"]}>
              <div className="omga-02__content-text section-title order-lg-1">
                <Text color="light" mb={4}>
                  <FlopFormattedMessageWrapper id="products.flopgto.patrik.title" />
                </Text>
                <a href="#pricing">
                  <Button bg="red">
                    <FlopFormattedMessageWrapper id="products.flopgto.patrik.cta" />
                  </Button>
                </a>
              </div>
            </Section>
          </Col>
        </Row>
      </Container>
    </div>
  </Section>
);

export default GtoPatrikWins;
